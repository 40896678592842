<script setup>
const loggedIn = useState('isLoggedIn');
</script>

<template>
	<div>
		<div v-if="loggedIn">
			<TopBar />
			<h1
				class="mb-8 text-4xl font-extrabold text-primary-700 md:text-5xl lg:text-6xl text-center mt-10"
			>
				Metgis Weather Warnings
			</h1>
			<div class="items-center">
				<div class="mx-auto md:w-9/12 xl:w-7/12">
					<h1>Description</h1>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua.
						Tincidunt praesent semper feugiat nibh sed pulvinar proin. Leo in
						vitae turpis massa sed elementum tempus egestas. Odio morbi quis
						commodo odio aenean. Senectus et netus et malesuada fames ac.
						Blandit massa enim nec dui nunc. Blandit massa enim nec dui nunc
						mattis enim. In vitae turpis massa sed elementum tempus. Nunc
						scelerisque viverra mauris in. Nisl suscipit adipiscing bibendum est
						ultricies integer quis auctor. Cursus euismod quis viverra nibh.
						Lorem dolor sed viverra ipsum nunc. Magna sit amet purus gravida
						quis blandit. Metus aliquam eleifend mi in nulla. Vulputate mi sit
						amet mauris commodo quis imperdiet massa tincidunt. Proin fermentum
						leo vel orci porta non. Diam volutpat commodo sed egestas egestas
						fringilla. Suscipit adipiscing bibendum est ultricies integer quis
						auctor elit. Ipsum consequat nisl vel pretium lectus quam id leo.
						Euismod in pellentesque massa placerat duis ultricies lacus sed
						turpis. Dolor morbi non arcu risus quis varius quam quisque. Nullam
						vehicula ipsum a arcu cursus vitae congue mauris. Velit egestas dui
						id ornare arcu odio. Aenean euismod elementum nisi quis. Est lorem
						ipsum dolor sit amet consectetur. Diam in arcu cursus euismod quis.
						Sed pulvinar proin gravida hendrerit lectus. Vestibulum rhoncus est
						pellentesque elit ullamcorper dignissim cras tincidunt. Ut sem nulla
						pharetra diam sit amet nisl. Nibh mauris cursus mattis molestie a
						iaculis at erat. Est ultricies integer quis auctor elit sed.
						Maecenas volutpat blandit aliquam etiam erat velit. Interdum
						consectetur libero id faucibus. Amet nisl purus in mollis nunc sed.
						Velit euismod in pellentesque massa. Enim ut tellus elementum
						sagittis vitae et. Faucibus interdum posuere lorem ipsum. Fusce ut
						placerat orci nulla pellentesque dignissim enim sit amet. Diam sit
						amet nisl suscipit adipiscing bibendum. Neque aliquam vestibulum
						morbi blandit cursus risus at ultrices. Placerat vestibulum lectus
						mauris ultrices eros in cursus turpis. Velit laoreet id donec
						ultrices. Volutpat sed cras ornare arcu dui vivamus arcu felis
						bibendum. Euismod lacinia at quis risus. Massa massa ultricies mi
						quis hendrerit. Vulputate dignissim suspendisse in est ante in nibh
						mauris. Ac turpis egestas integer eget aliquet nibh praesent
						tristique. Elit duis tristique sollicitudin nibh sit amet commodo
						nulla facilisi. Urna id volutpat lacus laoreet non curabitur gravida
						arcu ac. Sed nisi lacus sed viverra tellus in hac habitasse platea.
						Porta nibh venenatis cras sed. Maecenas sed enim ut sem viverra
						aliquet. Habitant morbi tristique senectus et. Sed libero enim sed
						faucibus turpis in eu. Euismod lacinia at quis risus sed vulputate.
						Proin fermentum leo vel orci porta non pulvinar neque laoreet.
						Tempus imperdiet nulla malesuada pellentesque elit eget gravida.
						Egestas maecenas pharetra convallis posuere. Pulvinar sapien et
						ligula ullamcorper. Interdum posuere lorem ipsum dolor sit amet
						consectetur adipiscing elit. Enim lobortis scelerisque fermentum dui
						faucibus. Egestas erat imperdiet sed euismod nisi porta lorem mollis
						aliquam. Dignissim cras tincidunt lobortis feugiat vivamus at augue
						eget. Ut etiam sit amet nisl purus in mollis. Nulla facilisi morbi
						tempus iaculis urna id volutpat. Purus ut faucibus pulvinar
						elementum integer enim neque.
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
