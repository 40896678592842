import axios from 'axios';
import { useState } from '#app';

const BASE_URL = '/magento/rest/V1';

export const useAuth = () => {
	const token = useState('magentoToken', () => '');
	const user = useState('user', () => null);
	const isLoggedIn = useState('isLoggedIn', () => false);
	const isAdmin = useState('isAdmin', () => false);

	const login = async (email: string, password: string) => {
		try {
			let tokenEndpoint;
			if (email === 'shop') {
				tokenEndpoint = `${BASE_URL}/integration/admin/token`;
				isAdmin.value = true;
			}
			else {
				tokenEndpoint = `${BASE_URL}/integration/customer/token`;
				isAdmin.value = false;
			}

			const response = await axios.post(tokenEndpoint, {
				username: email,
				password: password,
			});
			if (response.status !== 200)
				throw new Error('Auth failed check credentials');

			token.value = response.data;
			isLoggedIn.value = true;

			await fetchUser();
		}
		catch (error) {
			console.error('Login failed', error);
			token.value = '';
			isLoggedIn.value = false;
			throw error;
		}
	};

	const logout = () => {
		token.value = '';
		isLoggedIn.value = false;
		user.value = null;
	};

	const fetchUser = async () => {
		if (!isAdmin.value) {
			try {
				const response = await axios.get(`${BASE_URL}/customers/me`, {
					headers: {
						Authorization: `Bearer ${token.value}`,
					},
				});
				user.value = response.data;
			}
			catch (error) {
				console.error('Failed to fetch user data', error);
			}
		}
	};

	return {
		token,
		user,
		isLoggedIn,
		login,
		logout,
		fetchUser,
	};
};
