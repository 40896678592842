<script setup>
const route = useRoute();
</script>

<template>
	<div>
		<NuxtRouteAnnouncer />
		<NuxtPage />
		<UNotifications class="z-1" />
	</div>
</template>
