export default defineNuxtRouteMiddleware(async (to, from) => {
	const isAdmin = useState('isAdmin');
	const user = useState('user');
	const isLoggedIn = useState('isLoggedIn').value;

	if (isAdmin.value || to.path === '/register' || to.path === '/login' || !isLoggedIn) return;

	const response = await $fetch('/api/isenabled', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: {
			username: user.value.email,
		},
	});

	if (response !== 'OK') {
		const router = useRouter();
		router.push('/register');
	}
});
